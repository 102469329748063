jQuery(function(){

    //Hamburger Menu
    var hamburger = jQuery(".hamburger");
    hamburger.on("click", function(e) {
        hamburger.toggleClass("is-active");
        // Do something else, like open/close menu
    });

    //Push Menu
    var menuRight = document.getElementById( 'cbp-spmenu-s2' ),
        showRightPush = document.getElementById( 'showRightPush' ),
        body = document.body;

    showRightPush.onclick = function() {
        classie.toggle( this, 'active' );
        classie.toggle( body, 'cbp-spmenu-push-toleft' );
        classie.toggle( menuRight, 'cbp-spmenu-open' );
    };

    //Fixed Panel on Scroll
    var elementPosition = jQuery('.header').offset();
    var headerHeight = jQuery('header').height();

    jQuery(window).on('scroll', function() {
        if (jQuery(window).scrollTop() > (elementPosition.top )) {
            jQuery('.header').addClass('header--fixed');
            jQuery('.cbp-spmenu').addClass('mobile');
        } else {
            jQuery('.header').removeClass('header--fixed');
            jQuery('.cbp-spmenu').removeClass('mobile');
        }
    });

    // Scroll from home
    //jQuery(window).scroll(function() {
        if ( jQuery(window).scrollTop() == jQuery('#home').offset().top ) {
            jQuery(window).one('scroll', function() {
                jQuery('html,body').animate({
                    scrollTop: jQuery('#about').offset().top
                }, 400);
            });
        }
    //});

    /**
     * Smooth Scroll:
     */

    /**
     * This part causes smooth scrolling using scrollto.js
     * We target all a tags inside the nav, and apply the scrollto.js to it.
     */
    jQuery(".main-menu a, .cbp-spmenu a").click(function(evn){
        evn.preventDefault();
        jQuery('html,body').scrollTo(this.hash, this.hash);
    });

    /**
     * This part handles the highlighting functionality.
     * We use the scroll functionality again, some array creation and
     * manipulation, class adding and class removing, and conditional testing
     */
    var aChildren = jQuery(".main-menu ul li, .cbp-spmenu ul li").children(); // find the a children of the list items
    var aArray = []; // create the empty aArray
    for (var i=0; i < aChildren.length; i++) {
        var aChild = aChildren[i];
        var ahref = jQuery(aChild).attr('href');
        aArray.push(ahref);
    } // this for loop fills the aArray with attribute href values

    jQuery(window).scroll(function(){
        var windowPos = jQuery(window).scrollTop(); // get the offset of the window from the top of page
        var windowHeight = jQuery(window).height(); // get the height of the window
        var docHeight = jQuery(document).height();

        for (var i=0; i < aArray.length; i++) {
            var theID = aArray[i];
            var divPos = jQuery(theID).offset().top; // get the offset of the div from the top of page
            var divHeight = jQuery(theID).height(); // get the height of the div in question
            if (windowPos >= divPos && windowPos < (divPos + divHeight)) {
                jQuery("a[href='" + theID + "']").addClass("active");
            } else {
                jQuery("a[href='" + theID + "']").removeClass("active");
            }
        }

        if(windowPos + windowHeight == docHeight) {
            if (!jQuery(".main-menu ul li:last-child a, .cbp-spmenu ul li:last-child a").hasClass("active")) {
                var navActiveCurrent = jQuery(".active").attr("href");
                jQuery("a[href='" + navActiveCurrent + "']").removeClass("active");
                jQuery(".main-menu ul li:last-child a, .cbp-spmenu ul li:last-child a").addClass("active");
            }
        }
    });

    //Contact Form

    var respMessage = jQuery('.contact__resp');

    function responseMessage(text){
        respMessage.find('span').text(text);
    }

    function showResponseMessage(){
        respMessage.removeClass('hidden');
    }

    function hideResponseMessage(){
        respMessage.find('span').text('');
        respMessage.addClass('hidden');
    }

    hideResponseMessage();

    $('#send-message').on('click', function() {

        var name = jQuery('#contact__name').val();
        var email = jQuery('#contact__email').val();
        var message = jQuery('#contact__message').val();

        if (name == '' || email == '' || message == '') {
            responseMessage('Please make sure to fill all fields and try agian.');
            showResponseMessage();
        }
        else{
            hideResponseMessage();
            $.ajax({
                url: 'form.php',
                method: 'post',
                data: {
                    'name': name,
                    'email': email,
                    'message': message
                },
                success: function(result) {
                    responseMessage('Your message was sent successfully.');
                    showResponseMessage();
                }
            });
        };

    });

});